.result-count{
    font-family: 'Outfit', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #000;
}

.pagination.table-result{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    width: 700px;
    margin-top: 30px;
}
.pagination.table-result nav{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.pagination.table-result .pages{
    display: flex;
    flex-direction: row;
    gap: 1px;
}
.pagination.table-result .page-number{
    font-family: 'Outfit', sans-serif;
    height: 40px;
    width: 40px;
    font-size: 13px;
    font-weight: 600;
    background-color: #fff;
    border: none;
    cursor: pointer;
}
.pagination.table-result .page-number:hover{
    color: #fff;
    background-color: #000;
}
.pagination.table-result .current-page,
.pagination.table-result .button-prev,
.pagination.table-result .button-next{
    width: 40px;
    height: 40px;
    color: #fff;
    border: none;
    background-color: #000;
}
.pagination.table-result .page-gap{
    display: flex;
    justify-content: center;
    align-self: flex-end;
    width: 40px;
}

.pagination.table-result .total-pages{
    font-size: 14px;
}