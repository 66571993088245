.container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.main{
    height:120px;
    display: flex;
    flex-direction: column;
    padding: 0 59px;
    box-sizing: border-box;
    background-color: #E5ECF3;
}
.container .header{
    height: 35%;
}

.main .content-chart{
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
   
}

.main .milestone-column{
    position: relative;
    flex: 1;
    border-right: 1px dotted #7B88A1;
    box-sizing: border-box;
}
.main .milestone-column:first-child{
    border-left: 1px dotted #7B88A1;
}
.main .milestone-column .percent-marker{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px; 
    bottom: -20px;
    left: -20px;
    z-index: 100;
}
.main .milestone-column:first-child .percent-marker{
    display: none;
}
.main .milestone-column .percent-marker .grass-icon{
    position: absolute;
    bottom:20px;
    left: 5px;
}
.main .milestone-column .percent-marker .label{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 40px;
    font-size: 12px;
    font-weight: 600;
    color: #4D6275;
    background-color: #cad5e0;
}

.main .milestone-column .markers{
    float: left;
    display: flex;
    align-self: flex-start;
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    color: #000;
    padding: 0 10px;
    background-color: #fff;
}
.main .kangaroo{
    position: absolute;
    bottom:0;
    
}

.position-bar{
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -5px;
    z-index:1;
}
.position-bar .current-pos{
    height: 100%;
    background-color: #4D6275;
}


.container .footer{
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    height: 66px;
}
.container .footer .button-prev,
.container .footer .button-next{
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.container .footer .button-prev{
    border-right: 1px solid #ccc;
}
.container .footer .button-prev.true{
    color: #000;
}
.container .footer .button-next{
    border-left: 1px solid #ccc;
}
.container .footer .button-next.false{
    color: #949cad;
}

.container .footer .content-grid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
}
.container .footer .content-grid .column{
    display: flex;
    flex-direction: column;
    gap:3px;
    justify-content: center;
    width: 20%;
    height: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #545866;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
}
.container .footer .content-grid .column:last-child{
    border: none;
}

.container .footer .content-grid .column strong{
    font-size: 17px;
    font-weight: 600;
    color: #000;
}