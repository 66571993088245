/* Reset styles */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* margin: 0 auto*/


h1, h2, h3, h4, h5, h6, p, .h1, .h2, .h3, .h4, .h5, .h6 {
  padding: 0px;
  margin: 0px;
}


:root{
  --duration: #4e71a0;
  --totalJumps:#04ac0c;
  --totalJumps-RGB: 0, 176, 8;
  --totalTime:#005ae2;
  --totalCalories:#f5690c;
}




.App {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /*Full screen height wrapper*/
}
.App.theme-dark{
  background: linear-gradient(to bottom,#1f2e41, #121922);
}

.App.theme-light{
  background: #1B202B
}
.background-popup{
  width: 100%;
  min-height: 100%; /*Full screen height wrapper*/
  background-color: #000;
  position: absolute;
  top: 0;
  z-index: 1000;
  opacity: 0.5;
}


.dashboard-workout{
  background: #acb2be
}
.App.About,
.App.settings,
.App.privacy-policy{
  background: #ffffff
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}





/* Begin Main Navigation style: */
.main{
  font-family: 'Anton', sans-serif;
  position: relative;
  display:flex;
  flex-direction: row;
  gap: 5em;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-bottom:150px;
  padding-top: 50px;
}
.main.default{
  background: #182030
}
.main.home{
  padding: 0;
  background: #f7f8fa
}

.create-goal-theme{
  background-color: #fff;
}


.main.theme_dark{
  background: #45567a
}
.main.theme_light{
  background: #f7f8fa
}
.main.theme_grey{
  background:#C3CAD9
}

.content{

  display:flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.main h4{
  font-size: 50px;
  color: #f3f6ff;
  line-height: 1.10em;
  text-align: center;
}




.page-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 700px;
}
.page-container h3{
  font-family: 'Anton', sans-serif;
  font-size: 35px;
  color: #000;
}
.page-container p{
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 14.5px;
  text-align: center;
  font-weight: 500;
}



.content{
  width:60%;
  
}
.header-logo{
  
  width: 161px;



}
.intro-text{
  width: 600px;
  font-family: 'Kanit', sans-serif;
  font-size: 30px;
  text-align: center;
  font-weight: 700;

  text-transform:uppercase;
  align-items: center;
  justify-content: center;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #161616, #454549);

}
p{
  width:500px;
  font-family: 'Outfit', sans-serif;
  text-align: center;
  font-size: 14.5px;
  font-weight: 500;
  color:#232530;
  line-height: 19px;
}

.joinButton{
  font-family: 'Saira', sans-serif;
    width:130px;
    height:50px;
    line-height: 50px;
    background: linear-gradient(to bottom,#2a56b6, #113d91);
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-decoration: none;
    color: #f0f1f1;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}
.joinButton:hover {
  opacity: 0.7;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(260deg);
  }
}




#account-login p .link{
  text-decoration: none;
  color: #2b4ec4;
  font-weight: 600;
}
#account-login p .link:hover{
  text-decoration: underline;
}
#account-login .register-link{
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: #222224;
  font-size: 12.5px;
  font-weight: 500;
  text-align: center;

}
#account-login #status{
  width: 100%;
  height:30px;
}

#account-login .input-field{
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns:20px 1fr 25px;
  width:100%;
  height: 50px;
  font-size: 12.5px;
  font-weight: 500;
  color:#141416;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #EFF4FC;
  border: 1px solid #CED2DF;
  border-radius: 2px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  align-items: center;
  box-sizing: border-box;
}
#account-login .input-field.user{
  grid-template-columns:20px 1fr;
}

#account-login .input-field::placeholder{
  color: #838F9A;
  font-weight: 500;
}





#animated-result-count{
  text-align: center;
}
#animated-result-count h4{
  font-family: 'Saira', sans-serif;
  font-size: 30px;
  font-weight: 700;

}
#animated-result-count span{
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: 500;

}
.blur{
  transition: all 1s ease-in-out;
  filter: blur(2px);
}

.App.privacy-policy .content{
  font-family: 'Open Sans', sans-serif;
  display: flex;
  width: 500px;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}
.App.privacy-policy h2{
    font-size: 40px;
    color: #000000;
    font-weight: 800;
    margin-bottom: 50px;
}
.App.privacy-policy .content p{
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12.5px;
  font-weight: 500;
  color: #171718;
  text-align: left;
  margin-top: 20px;
}
.App.privacy-policy .content p strong{
  font-size: 15px;
  font-weight: 800;
}

/* Footer Style */




footer p{
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  color: #cbcdd3;
}

footer .footer_nav{
  display: flex;
  flex-direction: row;
  gap: 0.7em;
}
footer .footer_nav .fn-button{
  font-family: 'Russo One', sans-serif;
  font-size: 13px;
  color: #e7e5e5;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.5s ease-in-out;
}
.footer_nav .fn-button{
  color: #e7e5e5;
}

.App.dashboard footer .fn-button{
  color: #0f121a;
}
.App.dashboard footer .fn-button:hover{
  color: #030303;
}


.path-wave{
  position: absolute;
  width:100%;
  height:100px;
  bottom:0;
}
.path{
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='100%' preserveAspectRatio='none' viewBox='0 0 1440 85'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1129%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c41 C 48%2c48 144%2c79.4 240%2c76 C 336%2c72.6 384%2c23.2 480%2c24 C 576%2c24.8 624%2c77.4 720%2c80 C 816%2c82.6 864%2c39.8 960%2c37 C 1056%2c34.2 1104%2c71.4 1200%2c66 C 1296%2c60.6 1392%2c21.2 1440%2c10L1440 85L0 85z' fill='rgba(28%2c 32%2c 48%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1129'%3e%3crect width='1440' height='85' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  bottom: 0;
}



footer .footer_nav .fn-button:hover{
  color:#ffffff;
}
footer .footer-social-nav{
  display: flex;
  flex-direction: row;
  gap: 0.7em;
  padding-top: 10px;
}
footer .footer-social-nav .external-link{
  color:#d9dbe7;
  transition: color 0.5s ease-in-out;
}
footer .footer-social-nav .external-link:hover{
  color: #5b6388;

}


.page-not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8em;
  color: #fff;
}
.page-not-found h1{
  font-size: 40px;
  text-align: center;
}
.page-not-found span{
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #d4dce6;
  text-align: center;
}
.page-not-found .back-home{
  width: 160px;
  height: 50px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 50px;
  color:  #fff;;
  text-decoration: none;
  text-align: center;
  background: #0e0d0d;
  border-radius: 5px;
}