.container{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 235px;
    background-color: #fff;
    margin-bottom: 10px;
}
.container .header{
    height: 50px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.container .header nav{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
}
.container .header nav .nav-button{
    font-family: 'Outfit', sans-serif;
    height: 50px;
    width: 120px;
    border: none;
    outline: none;
    font-size: 13.5px;
    font-weight: 600;
    text-transform: capitalize;
    background-color: #fff;
    cursor: pointer;
    border-right: 1px solid #ccc;
}
.container .header nav .nav-button.true{
    color: #fff;
    background-color: #000;
}

.container .footer{
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    height: 66px;
}
.container .footer .button-prev,
.container .footer .button-next{
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.container .footer .button-prev{
    border-right: 1px solid #ccc;
}
.container .footer .button-prev.true{
    color: #000;
}
.container .footer .button-next{
    border-left: 1px solid #ccc;
}
.container .footer .button-next.false{
    color: #949cad;
}

.container .footer .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
}
.container .footer .content .column{
    display: flex;
    flex-direction: column;
    gap:3px;
    justify-content: center;
    width: 20%;
    height: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #545866;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
}
.container .footer .content .column:last-child{
    border: none;
}

.container .footer .content .column strong{
    font-size: 17px;
    font-weight: 600;
    color: #000;
}
.container .main{
    position: relative;
    width: 100%;
    height: 120px;
    border: 1px solid #fff;
    padding: 0 58px;
    box-sizing: border-box;
    background-color: #E5ECF3;

}
.container .footer{
    width: 100%;
    height: 50px;

    box-sizing: border-box;
}