:root {
  --backgroundTextColor: #f6f8ff;
}
.background{
    
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7); 
    z-index: 100;
    display: none;
}

#dashboardPageLoader{
  justify-content: center;
  align-items: center;
}
#dashboardPageLoader .hexShape{
  display: flex;
  height: 80px;
  width: 80px;
  align-self: center;
  background: rgb(248, 249, 253);
  -webkit-clip-path: polygon(
    50% 0%,
    95% 25%,
    95% 75%,
    50% 100%,
    5% 75%,
    5% 25%
  );
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  align-items: center;
  justify-content: center;
}
#dashboardPageLoader .load-text{
    font-family: 'Saira', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #e3ebf1;
    text-align: center;
    margin-top: 10px;
}
#dashboardPageLoader .loadAnimation{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.header{
    width: 800px;
}


.tableLoad{
    font-family: 'Saira', sans-serif;
    display: flex;
    height: 300px;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
    
}
.tableLoad .title{
    font-size: 13px;
    text-transform: capitalize;
}





.container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 980px;

    gap:0.8em;

}
.container #header{
    position: relative;
    font-family: 'Saira', sans-serif; 
    width: 750px;
    margin-bottom: 20px;
}
.container #header h4{
    float: left;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}


.container .gridColumnTop{
    display:grid;
    width: 820px;
    grid-template-columns:35% 65%;
  
    border-radius: 20px;
   align-self: flex-end;
    box-sizing: border-box;

}
.container .gridColumnTop .totals{
    font-family: 'Roboto', sans-serif; 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 20px;
    border-right: 1px dotted #ccc;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-sizing: border-box;
    background: linear-gradient(to bottom,#102040, #010101);
}
.container .gridColumnTop .totals #Headline{
    display: flex;
    flex-wrap: wrap;
    height:auto;
    top: 20px;
    left: 20px;
}
.container .gridColumnTop .totals #Headline h5{
    font-family: 'Saira', sans-serif; 
    font-size: 23px;
    font-weight: 700;  
}
.container .gridColumnTop .totals #Headline p{
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin:0;
}
.container .gridColumnTop .totals .gridBoxes{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 150px;
    margin-top: 20px;
    box-sizing: border-box;
    
}
.container .gridColumnTop .totals .gridBoxes .box{
    width: 50%;
    padding-right: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}
.gridColumnTop .totals .gridBoxes .box .boxTitle{
    font-size: 11.5px;
    color: #c4cbd1;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 2px;
}
.gridColumnTop .totals .gridBoxes .box .boxTitle .colorInd{
    float:left;
    width:8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
}

.gridColumnTop .totals .gridBoxes .box .boxValue{
    font-family: 'Saira', sans-serif; 
    font-size: 20px;
    font-weight: 800;
    color: rgb(235, 235, 235);
}
.gridColumnTop .totals .gridBoxes .box .formatDuration{
    font-family: 'Saira', sans-serif; 
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}
.gridColumnTop .totals .gridBoxes .box .formatDuration .label{
    font-family: 'Open Sans', sans-serif; 
    font-size: 10px;
    color: #a4a6ad;
}

.container .gridColumnTop .week_summary{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.week_summary .week_number{
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
}
.week_summary .week_date{
    font-size: 11px;
    color: #D2D8E0;
    margin-bottom: 10px;
    text-align: center;
}

.changeDates{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap:1em;
    font-size: 12px;
    color:#fff;
    margin-top: 15px;
 
}
.changeDates .column{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: #e2e4ec;
}
.changeDates .column .count{
    font-weight: 600;
    color: #fff;
}
.changeDates .column .arrow{
    display: flex;
    align-items: center;
    font-weight: 600;
  
}

/* Start of Main content, including Table Result */
.main-center{
    width: 780px;
    display: flex;
    flex-direction: column;
}



.totals{
    font-family: 'Saira', sans-serif;
    width: 500px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.totals .tf-label{

    font-size: 12px;
    color: #d8dbe4;
    margin-bottom: 5px;
}
.totals :is(.tf-value-workouts, .tf-value-loops, .tf-value-calories){
    font-size: 22px;
    color: #ffffff;
    font-weight: 800;
}
.totals .tf-value-duration{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.totals .tf-value-duration :is(.hour-value, .minute-value){
    font-size: 22px;
    color: #ffffff;
    font-weight: 800;
}
.totals .tf-value-duration .label{
    font-size: 12px;
    color: #dadae9;
}

.toggle-totals{
    font-family: 'Saira', sans-serif;
    float: left;
    display: inline-flex;
    flex-direction: row;
    gap: 5px;
    height:25px;
    border-radius: 2px;

     
    margin-top: 10px;
    padding: 5px 0px;
}
.toggle-totals .tt-button{
    cursor:pointer;
    border: none;
    min-width:50px;
    font-size: 12px;
    font-weight: 600;
    color: #eaedf1;
    border-radius: 5px;
    background: #1E263A;
    transition: background-color 0.3s ease-in-out;
}
.toggle-totals .tt-button.active,
.toggle-totals .tt-button:hover{
    background: #fff;
    color: #000;
}



.main-center .header .button-add{
    position: absolute;
    width:100px;
    height:40px;
    line-height: 40px;
    right: 0;
    top:0;
    font-size: 12px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    background-color: #0e62cf;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
}
.main-center .header .button-add:hover{
    background-color: #052fa3;
}






.chart{
    float:left;
    display: flex;

    width: 100%;
    height: 150px;

}

.TotalTopBar{
    display: flex;
    flex-direction: row;
    gap: 1.2em;
    width:650px;
    height:80px;
}
.TotalTopBar .columnBox{
    position: relative;
    font-family: 'Saira', sans-serif;
    width: 25%;
    height: 80px;
    background-color: #fff;
    box-shadow: 0px 0px 3px rgb(172, 170, 170);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}
.TotalTopBar .columnBox .cbTitle{
    font-family: inherit;
    font-size: 11px;
    font-weight: 600;
}

.container #Header{
    position: relative;
    display: flex;
    flex-direction: row;
    width:800px;
    height: 40px;
}
.container #Header #addButton{
    height: 40px;
    line-height: 40px;
    width: 120px;
    font-family: 'Saira', sans-serif;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    background-color: #4D7FDF;
    border: 1px solid #5E6BE2;
    border-radius: 2px;
    position: absolute;
    right: 0;
    box-shadow: 0px 0px 2px #000;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
}
.container #Header #addButton:hover{
    background-color: rgb(12, 11, 19);
    border: 1px solid #000;

}

.column_label{
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    text-transform: capitalize;
    margin-bottom: 5px;
}



.mc-header{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    margin-bottom: 20px;
    }
.mc-header .button-add{
    all: unset;
    float:left;
    font-family: 'Saira', sans-serif;
    width: 110px;
    height: 45px;
    line-height: 35px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #2e3847;
    border: 1px solid #28282b;
    border-radius: 2px;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: 1px -1px 5px 0px rgba(185, 186, 238, 0.42);
    -webkit-box-shadow: 1px -1px 5px 0px rgba(185, 186, 238, 0.42);
    -moz-box-shadow: 1px -1px 5px 0px rgba(185, 186, 238, 0.42);
    }
.mc-header .button-add:hover{
    background-color: #000000;
    }





.valueRow{
    display: flex;
    flex-direction: row;
}
.value{
    font-family: 'Saira', sans-serif; 
    font-size: 12px;
    font-weight: 700;
    color: #000000;
}


.gridColumns{
    display:grid;
    width: 780px;
    grid-template-columns:44% 55%;
    grid-gap:1%;
    padding-left: 10px;
    padding-right: 10px;
    background: linear-gradient(to bottom,#102040, #010101);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    box-sizing: border-box;
    align-self: flex-end;
}

.gradientDarkBox{
    font-family: 'Roboto', sans-serif;
    width: 100%;
}
.gradientDarkBox .dB_title{
    font-size: 13px;
    color: #F8F8F8;
    font-weight: 600;
}

.gradientDarkBox.weight{
    font-family: 'Roboto', sans-serif; 
    display: flex;
    flex-direction: column;
    height: 200px;
}
.gradientDarkBox.weight .header{
    display: flex;
    width: 100%;
    height: 25%;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom:1px solid #263346;
    box-sizing: border-box;
}
.gradientDarkBox.weight .content{
    display: flex;
    flex-direction: row;
    gap:1rem;
    padding-left: 25px;
    padding-right: 25px;
    height: 55%;
}
.gradientDarkBox.weight .footer{
    display: flex;
    height: 20%;
}
.gradientDarkBox.weight .footer .w_footerMonths{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    background-color: #11151E;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.gradientDarkBox.weight .footer .w_footerMonths .month{
    display: flex;
    height: 100%;
    font-size: 11px;
    color: #FFFFFF;
    text-align: center;
    align-items: center;
    justify-content: center;
}






#tdCanvas .canvasLines{
    width: 100%;
    height:25%;
    border-bottom: 1px dotted #434960;
}
#tdCanvas .canvasLines.top{
    border-top: 1px dotted #434960;
}

#tdCanvas .canvasData{
    display: flex;
    position: absolute;
    width: 100%;
    left: 50%; /* Move the child div to the middle of the parent horizontally */
    transform: translate(-50%, 0%);
    height: 100%;
    box-sizing: border-box;
    z-index: 10;
}
#tdCanvas .canvasData .cDataContent{
    position: relative;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    width: 100%;
    height: 100%;
    box-sizing: border-box;

}
.cDataContent .databar{
    display: flex;
    position: relative;
    width:100%;
    height: 100%;
    justify-content: center;
    cursor: pointer;
}
.cDataContent .databar:hover .dbHeight{
    background-color: #fff;
}
.cDataContent .dbHeight{
    position: absolute;
    width: 10px;
    bottom: 0;
    opacity: 1.0;
    background-color: #ccc;
}
.cDataContent .dbHeight.am{
    background-color: #F4B2B2;
}
.cDataContent .dbHeight.pm{
    background-color: #5C78A3;
}






.timePointText{
    position: absolute;
    
    font-size: 11px;
    font-weight: 700;
    color: #F3EEEE;
    top:12px;
    left:-10px;
  
}

.footerDate{
    font-size: 11px;
    font-weight: 600;
    color: #9599AA;
    margin-top: 10px;
}



.result-header{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.result-header .navigation{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.result-header .navigation .button-nav{
    position: relative;
    height: 38px;
    width: auto;
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
    background-color: #596680;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
}
.result-header .navigation .button-nav.true{
    color: #000;
    background-color: #ffffff;
}
.result-header .navigation .button-nav .name{
    float: left;
    width: auto;
    font-family: 'Outfit', sans-serif;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    border: none;
    padding: 0 12px;
    background-color: transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    cursor:pointer;
}
.result-header .navigation .button-nav.true .name{
    color: #000000;
}


.result-header .navigation .button-nav .filter{
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #000000;
    background-color: #aab7dd;
    border-radius: 20px;
    border: none;
    margin-top:-1px;
    cursor: pointer;
}
.result-header .navigation .button-nav .filter:hover{
    color: #fff;
    background-color: #040507;
}

.useRef{
    position: absolute;
}


.result-header .navigation .sortBy{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 13px;
    font-weight: 600;
    color: #f1f1f1;
    height: 30px;
    right: 0;
}
.result-header .navigation .sortBy .select-option{
    width: 120px;
    height: 35px;
    padding: 0 10px;
    border: 1px solid #D7D6D6;
    cursor: pointer;
    outline: none;
    margin-left: 20px;
}
.result-header .navigation .sortBy .order-buttons{
    width: 25px;
    height: 35px;
    border-top: 1px solid #D7D6D6;
    border-right: 1px solid #D7D6D6;
    border-bottom: 1px solid #D7D6D6;
    box-sizing: border-box;
    background-color: #fff;
}
.result-header .navigation .sortBy .order-buttons .button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    height: 50%;
    width: 100%;
    background-color:transparent;
    border: none;
    cursor: pointer;
}

.result-header .res-showing{
    font-family: 'Outfit', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #eeeeee;
}

.column-bar{
    position: relative;
    font-family: 'Outfit', sans-serif;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-top: 10px;
}
.activity-button{
    position: relative;
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 40px;
    width: 125px;
    font-size: 13.5px;
    font-weight: 700;
    color: #4D5A88;
    border-radius: 2px;
    border: 1px solid #C8D2E0;
    background-color: #D6E1FF;
    cursor: pointer;
    margin-top: auto;
}
.search-bar{
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    height: 40px;
    width: 260px;
    margin-top: auto;
    border-radius: 2px;
    background-color: #fff;
}
.search-bar .button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 35px;
    font-size: 20px;
    color: #7080AB;
    margin-left: 5px;
    border-radius: 2px;
    background-color: #D6E1FF;
}
.search-bar input[type=text]{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;
}

.modalPopupWorkout{
    position: relative;
    z-index: 11110;
    left: -50%;
}

.table_container{
    width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.table_container .user{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-self: flex-start;
    margin-bottom: 10px;
}
.table_container .user h3{
    font-size: 20px;
    font-weight: 800;
    color: #fff;
}
.table_container .user .username{
    font-size: 14px;
    font-weight: 400;
    color: #f0f0f0;
}



.table-top-bar{
    display:flex;
    flex-direction: row;
    min-height: 70px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
    }
.table-top-bar .button-add{
    all: unset;
    float:left;
    font-family: 'Saira', sans-serif;
    width: 110px;
    height: 45px;
    line-height: 35px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #152236;
    border: 1px solid #28282b;
    border-radius: 2px;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: 1px -1px 5px 0px rgba(185, 186, 238, 0.42);
    -webkit-box-shadow: 1px -1px 5px 0px rgba(185, 186, 238, 0.42);
    -moz-box-shadow: 1px -1px 5px 0px rgba(185, 186, 238, 0.42);
    }






.calendar{
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 0.1em;
    justify-content: center;
    align-items: center;
    color: #5C6481;
    width: 280px;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 1;
    background-color: #ffffff;
    
}

.calendar .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 230px;
    height: 30px;
    margin-bottom: 20px;
}
.calendar .header .toggleMonthBtn{
    display: flex;
    height:30px;
    width: 30px;
    color: #0c0d0e;
    cursor: pointer;;
    align-items: center;
    border: none;
}
.calendar .header span{
    display: block;
    width:200px;
    font-size: 12.5px;
    font-weight: 600;
    text-align: center;
}
.calendar thead{
    height: 25px;
    margin-bottom: 10px;
}
.calendar thead tr{
    font-size: 12.5px;
    font-weight: 600;
    color: #000;
    text-align: center;
}
.calendar table{
    width: 100%;
}
.calendar table td{
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}
.calendar table .date_number{
    color:#000;
}
.calendar table .date-default{
    cursor: default;
}
.calendar table .valid-date{
    cursor: pointer;
    border-radius: 2px;
}
.calendar table .checked-date,
.calendar table .valid-date:hover{
    background-color: #567ed4;
    color: #fff;
    border-radius: 2px;
}

.content-main{
    display: grid;
    grid-template-columns: 125px 1fr;
    gap: 20px;
}
.content-main .center{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.content-main .center .table{
    display: flex;
    flex-direction: column;
}
