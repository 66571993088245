/* stylesheet to handle the styling of components*/

.FormatDuration{
    font-family: 'Russo One', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.FormatDuration .FD-value{
    font-size: 15px;
    font-weight: 700;
    color: #0b0b0f;
}
.FormatDuration .FD-label{
    font-size: 11px;
    font-weight: 500;
    color: #2e2f35
}


.calendarWeek{
    font-family: 'Saira', sans-serif;
    display: flex;
    flex-wrap: wrap; 
    width: 100%
}
.calendarWeek .date{
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1a1b29;
    border-color: #c2c7d3;
    border-style: solid;
    box-sizing: border-box;
}
.calendarWeek .date .dayName{
    font-size: 11px;
    text-align: center;
}
.calendarWeek .date .dayNumber{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    padding-top: 2px;
    padding-bottom: 2px;
}
.calendarWeek .date .dayMonth{
    font-size: 11px;
    font-weight: 600;
    text-align: center;
}


/* SelectWeight */
.selectWeight{
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
}
.selectWeight .content-inner{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.selectWeight p{
    width: 100%;
    margin: 0;
    font-size: 12px;
    color: #88898A;
}
.selectWeight .field-label{
    text-align: center;
}
.selectWeight .grid{
    width: 100%;
}
.selectWeight .selection{
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 6% 88% 6%;
    margin-top: 10px;
}
.selectWeight .selection .button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CFDAEE;
    width: 100%;
    height:100%;
    cursor: pointer;
}
.selectWeight .selection .button.right{
    float: right;
}

.selectWeight .selection .selection-values{
    display: flex;
    flex-direction: row;
    background-color: #EAF0FA;
}
.selectWeight .selection .selection-values .value-number{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
}
.value-number .small-points{
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10px;
    bottom: 0;
}
.value-number .small-points .point{
    flex:1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.value-block .small-points .point span{
    float:left;
}

.selectWeight .selection .selection-values .value{
    height: 18px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 800;
    text-align: center;

}
.selectWeight .selection .selection-values .value.selected{
    font-size: 18px;
}
.pointer-select{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
.pointer-select .line{
    width: 3px;
    height: 6px;
    background-color: #64656e;
}


.selectWeight .selection .selection-values .value span{
    font-size: 12px;
}
.selectWeight .selection .selection-values .value .value-line{
    float:left;
    width:3px;
    height: 5px;
    background-color: #020202;
}


