
.container{font-family: 'Outfit', sans-serif;}
.container button{
    font-family: 'Outfit', sans-serif;
    background-color: #061bd8;
    cursor: pointer;
}
.container input{
    font-family: 'Outfit', sans-serif; 
}
.container h4{
    font-size: 15px;
    font-weight: 700;
    color: #000;
    text-align: left;
}
.container{
    font-family: 'Outfit', sans-serif;
    width: 370px;
    min-height: 300px;
    background-color: #fff;
    top:40px;
    left:0px;
    z-index: 1000;
    border-radius: 10px;
    cursor: normal;
}
.form-content{
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px 20px;
    border-top: 1px solid #ccc;
    box-sizing: border-box;
}
.form-content:first-child{
    flex-direction: column;
    gap: 0;
    padding: 20px 15px;
    border: none
}

.form-content .date{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.form-content .date .toggle-date{
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-gap: 2px;
}
.form-content .date .month{
    display: grid;
    grid-template-columns: 40px 1fr;
    height: 42px;
    width: 100%;
    background-color: #cccedb;
    border: 1px solid #CBC4C4;
    box-sizing: border-box;
}
.form-content .date .month label{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-left: -40px;
    text-align: center;
}
.form-content .date .toggle-date button{
    height: 42px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid #CBC4C4;
    box-sizing: border-box;
}

.week-toggle{
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    width: 100%;
}
.week-toggle .dates{
    display: flex;
    flex-direction: row;
}
.week-toggle .dates .single-date{
    display: flex;
    flex-direction: column;
    gap:1px;
    justify-content: center;
    align-items: center;
    width:calc(100%/7);
}
.week-toggle .dates .single-date .day-name{
    font-size: 12px;
    font-weight: 500;
}
.week-toggle button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: transparent;
  
    padding: 0;
    border: none;  
}


.week-toggle button.button-toggle{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #394357;
    background-color: #cccedb;
    margin-top: auto;
}
.week-toggle .single-date .number{
    font-size: 14px;
  
    font-weight: 600;
}

.week-toggle button.nonSelectDate{
    width: 100%;
    background-color: #DADAE1;
}
.week-toggle button.selectedDate,
.week-toggle button.nonSelectDate:hover{
    width: 100%;
    color: #fff;
    background-color: #061bd8;
}
.week-toggle .month-name{
    font-size: 12px;
    font-weight: 600;
}

.form-content .icon{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-top: auto;
    background-color: #CBCFDC;
}
.form-content .input{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
label{
    font-size: 13px;
    font-weight: 400;
    text-align: left;
}
.form-content input.input-time{
    width: 60px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    padding: 0;
    outline: none;
    text-align: center;
    background-color: #DADAE1;
}
.form-content .button-switch{
    width: 60px;
    height: 40px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: auto;
}


.form-content .values{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.form-content .values .column{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.form-content .values .column label{
    height: 20px;
    font-size: 13px;
    font-weight: 500;
}
.form-content .values .column input[type="text"]{
    width: 70px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    padding: 0;
    outline: none;
    text-align: center;
    background-color: #DADAE1;
}
.form-content .values .column input[type="text"].jumps{
    width: 100px;
    margin-right: 20px;
}

.form-content .values .button-weight{
    font-family: 'Outfit', sans-serif;
    margin-top: 25px;
    height: 40px;
    width: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    background-color: transparent;
    border: none;
}



.input-container{
    width: 100%;
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 60px;
    border: none;
    outline: none;
    text-align: left;
    box-sizing: border-box;
    background-color: #DADAE1;
}
.input-container button{
    font-family: 'Outfit', sans-serif;
    height: 100%;
    font-size: 13px;
    color: #fff;
    border: none;
    border: 5px solid #DADAE1;
    box-sizing: border-box;
}

.input-note,
.input-tags{
    width: 100%;
    height: 45px;
    border: none;
    padding: 0 10px;
    outline: none;
    text-align: left;
    box-sizing: border-box;
    background-color: #DADAE1;
}

.rating{
    display: flex;
    align-items: center;
}
.rating .star-rating{
    font-size: 25px;
    border: none;
    margin: 0 2px;
    padding: 0;
    background-color: transparent;
}

.save-button{
    height: 45px;
    width: 120px;
    font-size: 13px;
    color: #fff;
    margin-right: auto;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    
}