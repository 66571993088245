.home-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section-1{
    width: calc(100vw - (var(--scrollbar-width, 0px)));
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    padding-bottom: 55px;
    padding-top: 30px;
    background-color: #45567a;
}
.section-2{
    width: calc(100vw - (var(--scrollbar-width, 0px)));
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: #181c29;
}
.find-out-more{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    font-family: 'Saira', sans-serif;
    color: #fff;
    text-decoration: none;
}
.center{
    display: flex;
    flex-direction: column;

    gap: 10px;
    justify-content: center;
    align-items: center
}
.row{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.more-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width:50px;
    height: 50px;
    border-radius: 50px;
    background-color: #1ce783;
    position: fixed;
    
}


.home-container .content-left{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.home-container .content-right{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.home-container .logger{
    font-family: 'Saira', sans-serif;
  
    width: 120px;
    height:20px;
    line-height: 20px;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    color:#3C444F;
  
    text-align: center;
    background-color: #A6C5ED;
    margin-bottom: 10px;
    border-radius: 2px;

  }
.home-container p{
    font-family: 'Inter', sans-serif;
    font-size: 12.5px;
    line-height: 1.22em;
    font-weight: 400;
    color: #d4e0eb;
    padding-top: 20px;
    padding-bottom: 20px;
}
.home-buttons{
    display: flex;
    flex-direction: row;
    gap: 0.7em;
    margin-top: 10px;
    justify-content: center;
    font-family: 'Kanit', sans-serif;
}
.home-buttons .hb-button{
    display: flex;
    height: 45px;
    line-height: 45px;
    width: 120px;
    font-size: 13px;
    color: #f1f5fa;
    background: linear-gradient(180deg, #335DF4 0%, rgba(47, 91, 248, 0.75) 100%);
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    cursor:pointer;
    text-decoration: none;
}
.home-buttons .hb-button.login{
    background: linear-gradient(180deg, #1C2030 0%, rgba(22, 25, 35, 0.75) 100%);
}

.calculator{
    position: relative;
    font-family: 'Saira', sans-serif;
    background-color: #fff;
    border-radius: 20px;
    width: 320px;
    height: 350px;
    padding: 25px;
    box-sizing: border-box;
}
.calculator h4{
    font-size: 15px;
    text-align: left;
    color: #2B324E;
    font-weight: 600;
}
.calculator .title{
    display: flex;
    float: left;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #1e1f29;   
    border-radius: 2px;
}

.calc-loops{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.selection-content{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}

.selection-content .value-line .value-line-percent{
    height:5px;
    background-color: #BA51D4;
}

.selection-content .select{
    flex:1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    align-items: center;
    z-index:100;
    
}
.selection-content .select .top-label{
    font-size: 11px;
    font-weight: 600;
    text-align: center;
}
.selection-content .select .bottom-label{
    font-size: 11px;
}



.fadeIn-content{
    width: calc(100vw - (var(--scrollbar-width, 0px)));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2em;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    padding-top: 50px;
    padding-bottom: 50px;
}  
.fadeIn-content.dark-bg{
    background-color: #181c29;
}
.fadeIn-content.content-3{
    background-color: #f7f8fa;
}
h1{
    font-family: 'Saira', sans-serif;
    font-size: 35px;
    font-weight: 700;
    color: #f3f6ff;
}
.fadeIn-content.is-visible {
    opacity: 1;
}
.fadeIn-content.content-3 h1{
    color: #111214;
}
.fadeIn-content.content-3 p{
    font-family: 'Inter', sans-serif;
    padding: 0;
    margin: 0;
    width: 550px;
    font-size: 14.5px;
    font-weight: 500;
    color: #14171b;
}

  .hex-layer-1 {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    margin: 0px 0px;
    justify-content: center;
    align-content: center;
  }
  .hex-layer-2 {
    display: grid;
    justify-content: center;
    margin: -28px 0px;
    align-content: center;
    grid-template-columns: repeat(2, 0fr);
  }
 
  .hex {
    font-family: 'Saira', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    align-self: center;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    -webkit-clip-path: polygon(
      50% 0%,
      95% 25%,
      95% 75%,
      50% 100%,
      5% 75%,
      5% 25%
    );
    clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    transition: background 0.4s;
  }
  
  .hex:hover {
    background: #ffff00;
  }