.container{
    font-family: 'Saira', sans-serif;
    width: 480px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    background-color: #fff;
   
    border-radius: 10px;

}
.container h4{
    font-size: 20px;
    font-weight: 700;
    color: black;
}
.container .content{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 25px;
    border: 1px solid #ccc;
}
.container .content p{
    all: unset;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    color: #55595f;
    margin-bottom: 10px;
}

.container .content .element{
   
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e4e1e1;
    padding-bottom: 25px;
    margin-bottom: 30px;
  
}
.container .content .title{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
}


.container .content .input-label{
    float:left;
    font-family: 'Roboto', sans-serif;
    font-size: 11.5px;
    font-weight: 500;
    color: #606163;
    margin-bottom: 5px;
}
.container .content .element .new-password{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 20px;
}
.container .content .element .new-password .field{
    flex: 1;
}

.container .content .input-field{
    float:left;
    width: 200px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.container .content .element .checkbox-options{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.container .content .element .checkbox-options .checkbox{
    display: flex;
    align-items: center;
    width: 50px;
    font-size: 12px;
    padding:5px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.container .content .element .checkbox-options .checkbox input[type="checkbox"]{
    cursor:pointer;
    margin-right: 5px;
}


.container .content .element.deactivate{
    
}
.container .content .element.deactivate p{
    width: 100%;
    font-size: 11px;
    color: #363c44;
    line-height: 1.12em;
}
.element.deactivate .row{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.element.deactivate .row .button{
    align-self: flex-start;
    font-size: 12px;
    color: #d35d5d;
    padding: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #EF6C6C;
}

.save-button{
    float: right;
    width: 100px;
    height: 40px;
    font-size: 12px;
    color: #fff;
    background-color: #101011;
    border-radius: 2px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.save-button:hover{
    background-color: #000;
}