input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]::placeholder {
    color: #999;
    font-size: 15px;
    font-weight: 600;
}
.container{
    position: absolute;
    display: flex;
    width: 500px;
    flex-direction: column;
    background-color: #fff;

    font-family: 'Saira', sans-serif;
    margin-top: -110px;
    margin-bottom: 50px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 25px 0;
    z-index: 1111;
}
.close-button{
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    border: none;
    top:0;
    border-top-right-radius: 20px;
    background-color: #16171f;
    cursor: pointer;
}
.container h4{
    float: left;
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-align: center;
}



.form-field{
    position:'relative';
    width: 100%;
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background-color: #ecf3ff;
    border-bottom: 1px solid #d7dbe0;
    box-sizing: border-box;
}
.form-field.date,
.form-field.bodyweight,
.form-field.values{
    background-color: #fff;
}

.field-label{
   float: left;
   width: 100%;
    text-transform: capitalize;
    font-size: 12.5px;
    font-weight: 700;
    color: #1f1f24;
    text-align: left;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.field-description{
    width: 100%;
    font-size: 12px;
    line-height: 1.22em;
    color: #7b839b;
    text-align: left;
    padding-right: 20px;
    margin-bottom: 5px;
    box-sizing: border-box;
}



.container .box .instructions{
    font-family: 'Inter', sans-serif;
    font-size: 11.6px;
    font-weight: 600;
    text-align: left;
    color: #3f4455;
    line-height: 1.1em;
}


.container .field-label .required{
    font-weight: 700;
    color: #f04040;
    margin-left: 2px;
}





.required{
    color: #ce0808;
}


.form-field.date .grid-datetime{
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}
.form-field.date .grid-datetime .gd-date,
.form-field.date .grid-datetime .gd-time{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.form-field.date .grid-datetime .button{
    display: flex;
    flex-direction: row;
    width: calc(70px - (10px * 1));  
    gap: 5px;
    height: 30px;
    background-color: #cfdaee;
    padding: 5px;
    border-radius: 50px;
    margin-left: 15px;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}
.form-field.date .grid-datetime .button .button-name{
    width: 30px;
    font-size: 12px;
    font-weight: 600;
    padding-left: 2px;
    padding-right: 2px;
    box-sizing: border-box;
}
.form-field.date .grid-datetime .button .button-indicator{
    width: 25px;
    height: 20px;
    background-color: #526a8f;
    border-radius: 50%;
}
.form-field.date .grid-datetime .calendar-date{
    display: flex;
    position: relative;
    width: 220px;
    height: 40px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: 1px solid #C3CAD6;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}
.form-field.date .grid-datetime .calendar-date:hover{
    border: 1px solid #b7cef5;
}

.form-field.date .grid-datetime .calendar-date .selected{
    width:200px;
    font-family: 'Saira', sans-serif;
    font-size: 12px;
    font-weight: 600;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
}





.form-field.date .grid-datetime .time-pick{
    float:left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.form-field.date .grid-datetime .dropdown-time{
    width: 60px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #C3CAD6;
    cursor: pointer;
    text-align: center;
}
.form-field.date .grid-datetime  .middle-colon{
    line-height:45px;
    font-size: 20px;
    font-weight: 700;
    color:#0d0e11;
}
.form-field.date .calendar-block{
    position: absolute;
    z-index: 1000;
    top:40px;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #e1e5e9;
    border-radius: 10px;
    box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.21);
    -webkit-box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.21);
    -moz-box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.21); 
}






/* styling for the Main Value columns */    
.main-values{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}
.main-values .field-label{
    text-align: center;
}   
.main-grid-values{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 22% 1fr 1fr;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    box-sizing: border-box;
}
.main-grid-values .field-input{
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
}

.field-input .fit-input{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 50px;
    border: 1px solid #d5dbe7;
    background-color: #F0F3F5;
    box-sizing: border-box;
}
.field-input .fit-input input[type=number]{
    width: 55%;
    height: 36px;
    outline: none;
    border: none;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color:#1b1c2b;
    background-color: #edf1f7;
    border-radius: 50px;
    position: absolute;
    z-index: 100;
}
.field-input .fit-input input[type=number]::placeholder {
    color: #999;
    font-size: 15px;
    font-weight: 600;
  }
/* Chrome, Safari, Edge, Opera */
.field-input .fit-input input::-webkit-outer-spin-button,
.field-input .fit-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.field-input .fit-input input[type=number] {
  -moz-appearance: textfield;
}
.field-input .bottom-label{
    display: flex;
    align-self: center;
    height: 15px;
    line-height: 15px;;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    color: #475A78;
    font-weight: 500;
    align-content: center;
    text-align: center;
    text-transform: capitalize;
}
.convertToPounds{
    font-size: 12.2px;
    font-weight: 600;
    color: #424653
}









.inputColumn{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.inputMainBackground{
    height: 40px;
    width: 138px;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
   
}
.inputMainBackground .inputInner{
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: #cfdaee;
    border-radius: 50px;
}
.inputMainBackground .inputInner .button_x{
    position: relative;
    height: 100%;
    width: 50%;
    color: #526a8f;
    border: 1px solid #d5dbe7;
    background-color: #cfdaee;
    cursor: pointer;
}
.inputMainBackground .inputInner .button_x:hover{
    color:#16171f
}
.inputMainBackground .inputInner .button_x.left{
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;  
}
.inputMainBackground .inputInner .button_x.right{
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}
.inputMainBackground .inputInner .arrow{
    position: absolute;
   
}
.inputMainBackground .inputInner .button_x.left .arrow{
    left: 8px;
    top: 8px;
}
.inputMainBackground .inputInner .button_x.right .arrow{
    right: 8px;
    top: 8px;
}
.inputMainBackground .inputInner input[type=number]{
    position: absolute;
    width: 70px;
    height: 38px;
    text-align: center;
    border: 1px solid #d5dbe7;
    left: 30px;
    outline: none;
    z-index: 100;
}
.inputMainBackground .inputInner .inputField.userWeight{
    border-radius: 10px;

}

    .section.note .description{
        float:left;
        width: 350px;
        font-family: 'Roboto', sans-serif;
        padding:0;
        margin: 0;
        line-height: 1.2em;
        font-size: 11px;
        color:#898b99;
        font-weight: 500;
        text-align: left;
    }

    .form-field{
        border-bottom: 1px solid #D6DAE8;
        padding: 10px 20px;

    }
    .form-field .header-optional,
    .tag-container .tag-header,
    .form-field.rating .rating-header{
        position: relative;
        width: 100%;
        height: 25px;
    }
    .form-field .header-optional label,
    .tag-container .tag-header label,
    .form-field.rating .rating-header label{
        font-size: 12.5px;
        font-weight: 600;
        color: #3D4149
    }
    .form-field .header-optional .optional,
    .tag-container .tag-header .optional,
    .form-field.rating .rating-header .optional{
        position: absolute;
        right: 0;
        font-size: 11px;
        color: #9297a5;
    }

    .form-field textarea{
        width: 100%;
        height: 100px;
        font-size: 11.5px;
        color: #201f25;
        padding: 5px;
        background-color: #fafcff;
        border: 1px solid #c3cad6;
        box-sizing: border-box;
        margin-bottom: 5px;
        outline: none;
        resize: none;
    }
    .form-field .note-limit{
        align-self: flex-start;
        height: 15px;
        line-height: 15px;
        min-width:55px;
        font-size: 11px;
        color: #f4f7ff;
        text-align: center;
        border-radius: 2px;
        background-color: #131416;
    }


    .search-box{
        float: left;
        width: 100%;
        min-height:100px;
        align-self: flex-start;
        background-color: #fafcff;
        border: 1px solid #d1d6e2;
        padding: 10px;
        box-sizing: border-box;
    }
    .search-box .search-input{
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 40px;
    }
    .search-input .search-results{
    width:410px;
    height:auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    background: #FFFFFF;
    border: 1px solid #BBC0C8;
    padding:10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: absolute;
    top:40px;
    z-index: 2;
    }
    .count-tags{
        position: absolute;
        z-index: 1000;
        right: 10px;
        font-size: 11px;
    }
    .search-box input[type=text]{
        background-color: #edf3ff;
        width: 100%;
        height: 40px;
        font-size: 11.5px;
        color: #81888f;
        border: none;
        padding-left: 10px;
        padding-right: 30px;
        box-sizing: border-box;
        outline:none;
    }
   .search-box input[type=text]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #a9b5c2;
    }
    .search-box input[type=text]::-moz-placeholder { /* Firefox 19+ */
        color: #cbd6e2;
    }
    .search-box input[type=text]:-ms-input-placeholder { /* IE 10+ */
        color: #cbd6e2;
    }
    .search-box input[type=text]:-moz-placeholder { /* Firefox 18- */
        color: #cbd6e2;
    }
    .search-box input[type=text]::placeholder {
        color: #9faab6;
    }
   
    .tag-name{
        display: flex;
        float:left;
        align-items: center;
        align-self: flex-start;
        height: 30px;
        font-size: 12px;
        font-weight: 400;
        color: #e8edf8;
        background-color: #5d80c0;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 2px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }
    .tag-name:hover{
        background-color: #131418;
    }
    .tag-name .tag-count{
        font-weight: 300;
        font-size: 11px;
        margin: 0px 2px;
        color: #e8edf8;
    }
    .inner-list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        float: left;
        width: 100%;
        margin-top: 10px;
    }
    
    .form-field.rating{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;

    }
    .form-field.rating .star-rating{
        display: flex;
        flex-direction: row;
        width:150px;
    }
    .form-field.rating .star-rating .star{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12.5px;
        color : #A2A9BB;
    }
    .form-field.rating .star-rating .star:hover{
        color : #dba204;
    }



    .form-field.rating span{
        font-size: 11px;
        color: #748194;
        margin-top: 5px;
    }




    /* Footer: Includes Buttons -> Reset, Save */
    .footer{ 
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .footer .button{
        font-family: inherit;
        width: 130px;
        height: 50px;
        line-height: 50px;
        font-size: 13px;
        color: #fff;
        font-weight: 600;
        border-radius: 50px;
        margin-left: 5px;
        margin-right: 5px;
        border: none;
        cursor: pointer;
        right: 0;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
    .footer .button.save{
        background-color: #2a3ab3;
    }
    .footer .button.save:hover{
        background-color: #0317ca;
    }
    .footer .button.clear{
        background-color: #282934;
    }

    .footer .button-home{
        font-family: inherit;
        width: 130px;
        height: 50px;
        line-height: 50px;
        font-size: 13px;
        color: #fff;
        font-weight: 600;
        border-radius: 50px;
        margin-left: 5px;
        margin-right: 5px;
        border: none;
        cursor: pointer;
        right: 0;
        text-align: center;
        text-decoration: none;
        background-color: #020202;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }

    .column-option{
        font-family: 'Outfit', sans-serif;
        position: relative;
        display: flex;
        align-items: center;
        height: 45px;
        padding: 0 20px;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }
    .column-option strong{
        font-size: 13px;
        font-weight: 600;
        color: #000;
        text-transform: capitalize;
    }

    .errors{
        width: 100%;
        display: flex;
        align-self: flex-start;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        height:auto;
        font-size: 11px;
        color: #993434;
        padding: 10px 30px;
        box-sizing: border-box;
    }
    .errors .message{
        width: 100%;
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        justify-content: center;
        gap: 0.5em;
        padding: 8px;
        background-color: #fdd4d4;
        border: 1px solid #c79797;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .errors .message .error-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
    }
    .errors .message p{
        all:unset;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
    }
    .errors .message b{
        text-transform: capitalize;
        font-weight: 700;
        margin-right: 2px;
    }



 /* Edit Mode style: */
 .container.edit-mode .calendar-date{
    background-color: #edf1f7;
    cursor:not-allowed;
}
.container.edit-mode .calendar-date .selected{
    cursor:not-allowed;
}     
.container.edit-mode .update-message{
    padding: 10px;
    font-size: 12px;
    color: #000;
    line-height: 1.15em;
    text-align: center;
    background-color: #bcdbb5;
}
.container.edit-mode .update-message span{
    color: #000;
    font-size: 11.5px;
    font-weight: 600;
}   



.input-toggle-buttons{
    position: absolute;
    float: left;
    width: 100%;
    height:100%;
    background-color: rgb(207, 218, 238);
    border-radius: 50px;
}
.input-toggle-buttons .button{
    position:relative;
    height:50%;
    width: 60%;
    margin-left: 40%;
    text-align: right;
    padding-right: 10px;
    cursor: pointer;
    box-sizing: border-box;
}
.input-toggle-buttons .button.down{
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}
.input-toggle-buttons .button.up{
    border-top-right-radius: 50px;  
}
.input-toggle-buttons .button .arrow{
    margin-top: 0px;
    color:#526a8f;
    margin-left: 10px;
}
.input-toggle-buttons .button.up .arrow{
    margin-top: 2px;
}
.input-toggle-buttons .button.down .arrow{
    margin-top: -4px;
}
.input-toggle-buttons .button:hover .arrow{
    color: rgb(26, 23, 23);
}





.options-slider {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

  }
  .slider-w{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .slider-w-indicator{
    width: 40px;
    height: 5px;
    border-radius: 2px;
  }
  .slider-w-indicator.default{
    background-color: #8495B2;
  }
  .slider-w-indicator.active{
    background-color: #ECF3FF;
  }

  .slider-content{
    position: relative;
    display: flex;
    height: auto;
    align-items: center;
  }

.slider-button-next{
  
    width: 30px;
    height: 30px;
    background-color: #08080a;
    border: none;
    border-radius: 50%;

}
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .slide:nth-child(1) {
    left: 0%;
  }
  
  .slide:nth-child(2) {
    left: 100%;
  }
  
  .slide:nth-child(3) {
    left: 200%;
  }