.filter-container{
    position: absolute;
    width: 220px;
    display: flex;
    flex-direction: row; 
    gap: 1px;  
    top:45px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #a8a7a7;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}
.filter-month{
    position: absolute;
    width: 370px;
    display: flex;
    flex-direction: row; 
    gap: 1px;
    border: 1px solid #a8a7a7;
    padding: 25px 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    background-color: #fff; 
    border-radius: 5px; 
    top:45px;
    z-index:100;
}
.block{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(100% / 7 - 1px); 
    cursor: pointer;
}
.block .count{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 2px;
    z-index: 1000;
}
.block .value-row{
    position: relative;
    width: 100%;
    height: 40px;
}
.block .value-row .percent-value{
    position: absolute;
    width: 100%;
    bottom:0;
    background-color: rgb(182, 193, 204);
}
.block:hover .percent-value{
    background-color: rgb(113, 138, 163);
}
.block .label{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
}