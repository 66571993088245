.total_results{
    
}
.container{
    font-family: 'Outfit', sans-serif;
    width:800px;
    display: grid;
    grid-template-columns: 50px 50px 1fr 30px;
}
.container .timeline{
    width: 100%;
    height: 100%;
    padding-right: 15px;
    box-sizing: border-box;
}
.container .timeline .content{
    position: relative;
    height: 100%;
    width: 100%;
    border-right: 1px solid #ABB4C6;
}
.container .timeline .content .indicator{
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #96AEC7;
    right: -6px;
    top:25%;
}
.container .timeline .content .item-note{
    font-size: 25px;
    color: #fdfdfd;
    cursor: pointer;
}
.container .timeline .content .has-note{
    position: relative;
}
.container .timeline .content .has-note .tooltip{
    position: absolute;
    display: flex;
    align-self: flex-start;
    width: 350px;
    min-height: 40px;
    font-size: 12px;
    padding: 10px;
    text-align: left;
    filter: drop-shadow(1px 1px 1px #aaaaaa);
    background-color: #fff;
    top:-10px;
    border-radius: 5px;
    left:35px;

}
.container .timeline .content .has-note .tooltip .inner-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-self: flex-start;
}
.container .timeline .content .has-note:hover .tooltip{
    display: block;
}


.tooltip:after, .tooltip:before {
	right: 100%;
	top: 50%;
	border: 1px solid #ccc;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.tooltip:before {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #ffffff;
	border-width: 8px;
	margin-top: -20px;
}
.container .timeline .content .has-note .tooltip b{
    font-weight: 500;
}

.container .column-date{
    display: flex;
    flex-direction: column;
    height: 75px;
    padding-bottom: 5px;
    background-color: #D2D8E2;
    box-sizing: border-box;
}
.container .column-date .date-part-top{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 13px;
    color: #fff;
    box-sizing: border-box;
    background-color: #586370;
}
.container .column-date .date-part-middle,
.container .column-date .date-part-bottom{
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #3C3E4F;
    font-weight: 800;
    padding: 3px 0;
    box-sizing: border-box;
}

.container .column-main{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}


.container .column-main .content{
    background-color: #fff;
    padding: 10px 20px;
    border: 1px solid #DCD3D3;
    box-sizing: border-box;
}
.container.monthly .column-main .content{
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 20px;
}

.container .column-main .content-inner{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 90px;
    background-color: #fff;
}
.container .column-main .content-inner .datetime{
    font-size: 12.5px;
    font-weight: 500;
    color: #594F4F;
}
.container.daily .column-main .content-inner .grid{
    display: grid;
    grid-template-columns: 1fr 120px;
    height: 40px;
}
.container.weekly .column-main .content-inner .grid{
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 20px;
    height: 40px;
}
.container .column-main .content-inner .fields{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.container .column-main .content-inner .field{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.container .column-main .content-inner .field .column{
    display: flex;
    flex-direction: row;
    height: 20px;
    gap: 5px;
}

.container .column-main .content-inner .fields .label{
    color: #4E4A4A;
    font-size: 12px;
    font-weight: 500;
}
.container .column-main .content-inner .fields .field{
   width: calc(25% - 10px);
}


.container .column-main .content-inner .fields .column .value{
    font-size: 16px;
    font-weight: 800;
    color: #000000;
}
.container .column-main .content-inner .fields .column .ranking{
    display: flex;
    align-items: center;
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    padding: 0 5px;
    border-radius: 2px;
    background-color: #C0CCDB;
}

.footer{
    position: relative;
    display: block;
    height: 15px;
}
.footer .rated{
    float: left;
    display: flex;
    
}
.footer .id{
    position: absolute;
    font-size: 12px;
    color: #8FA2B8;
    font-weight: 500;
    right: 0;
}

.column-right{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
}
.column-right .checkbox{
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #928888;
    cursor: pointer;
}

.container .column-main .bars{
    display: flex;             /* Enable flexbox layout */
    justify-content: space-between; /* Optional: add spacing between items */
    height: 40px;
    gap:1px;
}
.container .column-main .bars .block{
    flex: 1;  
}
.container .column-main .bars .block .value{
    position: relative;
    display: flex;
    width: 100%;
    height: 25px;
    background-color: #cedde9;
    margin-bottom: 5px;
}
.container .column-main .bars .block .value .percent-value{
    position: absolute;
    width: 100%;
    bottom:0;
    

}
.container .column-main .bars .block .value .percent-value.true{
    background-color:var(--totalJumps);
}

.container .column-main .bars .block .label{
    height: 10px;
    font-size: 12px;
    text-align: center;
}

.calendar{
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}
.calendar .num-day{
    display: flex;
    justify-content: center;
    width: calc(100% / 7 - 2px); /* Accounts for gap or margin */
    height: 15px;
    font-size: 11.5px;
    font-weight: 600;
}
.calendar .num-blank{
    width: calc(100% / 7 - 2px); /* Accounts for gap or margin */
    height: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
}
.calendar .num-date,
.calendar .num-date-active{
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 7 - 2px); /* Accounts for gap or margin */
    height: 15px;
    font-size: 12px;
    font-weight: 600;
    background-color: #ced8e6;
    box-sizing: border-box;
}
.calendar .num-date{
    font-size: 11px;
    color: #717a83;
    font-weight: 600;
}
.calendar .num-date-active{
    color: #f8f8f8;
    background-color: var(--totalJumps);
}