* {-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
}




.form-w500{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 1000;
}


.form-w500.tags,
.form-w500.note{
    width: 500px;
    border-radius: 5px;
    padding-left: 0;
    padding-right: 0;
}
.form-w500.note{
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}
.form-w500.tags{
    gap: 0.5em;
}
















.column{
    font-family: 'Saira', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 0.7em;
}
.valueInput_label{
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 700;
    color: #272835;
    text-align: center;
}

.valueInput_wrapper{
    display: flex;
    width: auto;
    flex-direction: column;
    gap: 0.5em;
    height: auto;
   
    align-items: center;
  
}
.valueInput_wrapper .mains{
    float:left;
    display: flex;
    width: auto;
    height: 40px;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
    gap: 0.5em;

}
.valueInput_bottom{
    float:left;
    display: flex;
    width: 100%;

}
.valueInput_bottom .b_label{
    flex: 1;
    font-size: 11.5px;
    text-align: center;
}


.form-w500.tags .header{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #D9D9D9;
}
.form-w500.tags .header .search{
    position: relative;
    height: 35px;
    width: 200px;
    border: 1px solid #D9D9D9;
    outline: none;
}
.form-w500.tags .header .search input[type=text]{
    float:left;
    width: 200px;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    outline: none;
    box-sizing: border-box;
}
.form-w500.tags .header .tagButton{
    font-size: 12px;
    color:#fff;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #06138C;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
}
.form-w500.tags .header .tagButton:hover{
    background-color: #0a1896;
}
.form-w500.tags .content{
    float: left;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}
.form-w500.tags .content p{
    width: 100%;
    font-size: 12px;
    color:#444655;
    text-align: left;
    margin-bottom: 10px;
}
.form-w500.tags .content .inner-list{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 0.3em;
    flex-wrap: wrap;
    width: 100%;
    height: 80px;
    padding: 5px;
    background-color: #f1f2f8;
    border: 1px solid #ccc;
    box-sizing: border-box;
}
.form-w500.tags .content .inner-list .tag-name{
    display: flex;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #ffffff;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #aeb2c2;
    cursor: pointer;
}
.form-w500.tags .content .inner-list .tag-name:hover{
    border: 1px solid #434b69;
}
.form-w500.tags .content .tag-footer{
    float: left;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-style: italic;
    color: #383942;
    margin-top: 10px;
}











  .valueInput_bottom{
    display: flex;
  }
  .valueInput_bottom .label{
    flex:1;
    font-size: 12px;
    color: #3e3f4b;
    width: 50%;
    font-style: italic;
    display: inline-block;
    box-sizing: border-box;
  }
  .valueInput_bottom .minutes{
    padding-left: 20px;
  }
  .valueInput_bottom .seconds{
    padding-left: 0px;
  }



