.footer{
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  background-color: #1c2030;
}


  footer .content{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 800px;
    align-items: center;
    justify-content: center;
  }
  footer .scrollTop{
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    position: absolute;
    right: 0;
    font-size: 12px;
    font-weight: 600;
    color:#d0dbf0;
    cursor: pointer;
  }