header{
  display: flex;
  height: 60px;
  flex-direction: column;
  background: #45567a;

}
header .column-main{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height:60px;
  width: 100%;

}
  
  header .navigation{
    width: 950px;
    height: 100%;
    display: grid;
   grid-template-columns: 1fr 600px 1fr;
    position: relative;
    align-items: center;
  }
  header .navigation .site-title{
    font-family: 'Russo One', cursive;
    font-size: 15px;
    font-weight: 800;
    color: rgb(17, 22, 27);
    text-decoration: none;
  }

  .nav-links{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;

  }
  .nav-links .active,
  .nav-links .default{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 13.5px;
    font-weight: 700;
    color: #0F1318;
    text-decoration: none;
  }
 
  header .userButton{
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction:row;
    gap: 0.4em;
    width:85px;
    height: 35px;
    font-size: 13px;
    font-weight: 700;
    color: #484B5B;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 2px solid #1F1F1F;
    background: #FFF;
    transition: all 0.3s ease-in-out;
  }
  header .userButton:hover{
    background: #3074da;
    border-color: #3074da;
    color: #fff;
  }

  .loggedin-nav{

    display: flex;
    flex-direction: row;
    height: 5px;
    width:auto;
    align-items: center;
    background-color: #fff;
    right: 0;
    top: 0;
  }
  .loggedin-nav .loggedin-buttons{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-right: 30px;
  }
  .loggedin-nav .loggedin-button{
    display: flex;
    width:30px;
    align-items: center;
    justify-content: center;
  }
  .btn-add{
    color: #3d5cc5;
  }
  .btn-notification{
    color: #b3b8c9;
  }
  .btn-dashboard{
    color: #9099BA;
  }





.user-dropdown{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #CDD6E4;
  cursor: pointer;
}


.user-dropdown-menu{
    position: relative;
    font-family: 'Poppins', sans-serif;
    display: none;
    width: 170px;
    height: auto;
    position: absolute;
    right: -10px;
    top: 50px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 1px 2px 5px 0px rgba(85, 83, 83, 0.45);
    -webkit-box-shadow: 1px 2px 5px 0px rgba(85, 83, 83, 0.45);
    -moz-box-shadow: 1px 2px 5px 0px rgba(85, 83, 83, 0.45);
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 1000;
  }
.user-dropdown-menu::after{
  position: absolute;
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #ffffff;
	border-width: 8px;
}

  .user-dropdown-menu.show{
    display: block;
  }
  .user-dropdown-menu.hide{
    display: none;
  }

  .user-info{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 5px;
  }
  .user-info b{
    font-size: 12.5px;
    font-weight: 600;
  }
  .user-info .u-email{
    font-size: 10.5px;
    font-weight: 500;
    color: #747688;
  }

  .user-dropdown-menu .button{
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    font-family: inherit;
    font-size: 11px;
    font-weight: 600;
    color: #4e565a;
    text-decoration: none;
    border: none;
    padding: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
  }
  .user-dropdown-menu .button.settings{
    border-bottom: 1px solid #e2e3eb;
  }
  .user-dropdown-menu .button:hover{
    color: #000;
  }


  .textUsername{
    font-family: 'Saira', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #1e2025;
    text-align: right;
  }
  .textMemberDate{
    font-family: 'Saira', sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: #282a31;
    text-align: right;
    margin-top: 3px;
  }
  
  .rightNavUser{
    position: absolute;
    right: 0;
  }

.column-dashboard{
 
  width: 500px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.column-dashboard .navigation{
  width:100%;
  display: flex;
  flex-direction: row;
}
.column-dashboard .navigation .button{
  font-family: 'Saira', sans-serif;
  width: 120px;
  height:35px;
  font-size: 13px;
  font-weight: 600;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-bottom: 5px solid #C3CEE7;
}
.column-dashboard .navigation .button.active{
  border-bottom: 5px solid #6C7AAD;
}