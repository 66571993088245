.container{
    font-family: 'Outfit', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    box-sizing: border-box;
    background-color: #fff;
}
.header{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px 30px;
    background-color: #E5ECF5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
}

.title{
    font-size: 13.5px;
    font-weight: 700;
    text-align: left;
    color: #2c2c3d;
}

.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
}
.main{
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: column;
}
.main .renderGrid{
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
}

.grid-column{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height:100%;
   


}
.grid-cell{
    font-family: 'Rubik', sans-serif;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    height: 100%;
    font-size: 10px;
    font-weight: 500;
    color: #7f8aac;
    background-color: rgb(217, 224, 230);
    border: 1px solid #fff;
    box-sizing: border-box;
}
.grid-cell.blank{
    background-color: #fff;
}
.grid-cell.found{
    background-color: #07853b;
    cursor: pointer;
    color: #e9ebf0;
}


.results{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
    gap:15px;
    padding: 10px 0px;
}
.results .result-item{
    display: flex;
    flex-direction: row;
    gap: 5px;
    text-align: left;

   
}
.results .result-item .value{
    font-size: 12.5px;
     font-weight: 600;
     text-align: left;
}
.results .result-item .value .suffix{
    font-size: 11px;
}
.results .result-item .label{
    font-size: 12.5px;
    font-weight: 500;
    color: #29292b;
    text-align: left;
}

.months{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
    height:25px;
}
.months .month-name{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    font-weight: 600;
}


.renderDays{
    position: absolute;
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    right: 20px;
}
.renderDays .rd-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
}
.renderDays .rd-row-checkbox{
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    margin-right: 5px;
}
.renderDays .rd-row-value b{
    font-weight: 600;
    margin-right: 2px;
}


.atm-grid{
    width: 100%;
    box-sizing: border-box;
}
.atm-grid-cell{
    height:20px;
    background-color: #d9e0e6;
}

.footer{
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    border-top: 1px solid #ccc;
    height: 66px;
}
.footer .button-prev,
.footer .button-next{
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.container .footer .button-prev{
    border-right: 1px solid #ccc;
}
.container .footer .button-prev.false{
    color: #949cad;
}
.container .footer .button-prev.true{
    color: #000;
}
.container .footer .button-next{
    border-left: 1px solid #ccc;
}
.container .footer .button-next.false{
    color: #949cad;
}

.container .footer .content-grid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
}
.container .footer .content-grid .column{
    display: flex;
    flex-direction: column;
    gap:3px;
    justify-content: center;
    width: 20%;
    height: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #545866;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
}
.container .footer .content-grid .column:last-child{
    border: none;
}

.container .footer .content-grid .column strong{
    font-size: 17px;
    font-weight: 600;
    color: #000;
}