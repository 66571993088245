/* style for the placeholder text */
::-webkit-input-placeholder { /* Chrome, Safari, Opera */
    color: #727279;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #727279;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #727279;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #727279;
  }

  
.container{
    width: 342px;
    height: auto;
}
.container span{
   font-size: 12px;
   color: #585866
}
.confirm-terms{
    color: #000
}



.account{
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column; 
    width: 300px;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #D3DCE7;
    justify-content: center;
    border-radius: 20px;
    margin-bottom: 20px;
}
.account h5{
    font-family: 'Saira', sans-serif;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 10px;
  }
.account p{
    font-family: 'Roboto', sans-serif;
    width: 100%;
    line-height: 1.32em;
    font-size: 11.5px;
    font-weight: 400;
    color: #676F79;
    text-align: center;
    margin-bottom: 10px;
}
.account .field-content{
    float:left;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}
.field-label{
    font-size: 12px;
    margin-bottom: 5px;
}
.account .input-field{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns:40px 1fr 25px;
    width:100%;
    height: 50px;
    font-size: 12.5px;
    font-weight: 500;
    color:#141416;
    margin-bottom: 2px;
    background-color: #EFF4FC;
    border: 1px solid #CED2DF;
    border-radius: 2px;
    outline: none;
    padding-right: 10px;
    text-align: left;
    align-items: center;
    box-sizing: border-box;
    
}
.input-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width:40px;
    height: 100%;
    background-color: #DEE6F1;
    border-right: 1px solid #CED2DF;
}

.account .input-field.error{
    border: 1px solid #f35b5b;
}
.account .input-field input[type=text],
.account .input-field input[type=password]{
  font-family: 'Roboto', sans-serif;
  width: 100%;
  border:none;
  background-color: transparent;
  outline: none;
  font-size: 12.5px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  
}
.account .input-field  .show-password{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
}
.account .error-msg{
    display: grid;
    grid-template-columns: 15px 1fr;
    float:left;
    width: 100%;
    height:auto;
    font-size: 11px;
    color: #f35b5b;
    padding: 5px;
    background-color: #fddede;
    border: 1px solid #dfb9b9;
    align-items: center;
    box-sizing: border-box;
    margin-top: 15px;
    margin-bottom: 5px;
}
.error-msg span{
    width:100%;
    font-size: 11px;
    padding-left: 10px;
    box-sizing: border-box;
}
.error-msg .icon{
    width: 15px;
    height: 100%;
    font-size: 15px;
}

.account .password-link{
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    color: #161616;
    text-decoration: underline;
}
.account .button-login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
    font-family: 'Saira', sans-serif;
    width: 100%;
    height: 55px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    color: #ffffff;
    background-color:#3D5CCD;
    border-radius: 2px;
    transition: background-color 0.5s ease-in-out;
}
.account .button-login:hover{
    background-color: #161616;
}

.container .create-account{
    width: 100%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #121214;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
}
.container .create-account .create-button{
    font-family: 'Saira', sans-serif;
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    background-color: #030405;
    border-radius: 2px;
    margin-top: 10px;
}


.account.reset-password p{
    text-align: left;
}
.account.reset-password input[type=text]{
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 40px;
    font-size: 12px;
    background-color: #f6fbff;
    border: 1px solid #e8ecf0;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 2px;
    outline: none;
    margin-bottom: 10px;

}
.account.reset-password .button{
    font-family: 'Saira', sans-serif;
    width: 100%;
    height: 45px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    color: #ffffff;
    background-color:#272931;
    border-radius: 2px;
    transition: background-color 0.5s ease-in-out;
}
.account.reset-password .button:hover{
    background-color: #161616;
}
.field-columns{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin: 20px 0;
}
.input-field-register{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    background-color: #EFF4FC;
    height:45px;
    width: 100%;
    padding: 5px;
    border: 1px solid #CED2DF;
    border-radius: 2px;
    box-sizing: border-box;
}
.input-field-register.error{
    border: 1px solid #e75353;
}
.input-field-register input[type=text],
.input-field-register input[type=password]{
    height: 100%;
    width: 220px;
    font-size: 12px;
    background-color: #EFF4FC;
    border: none;
    outline: none;
}

.validate-icon{
    position: absolute;
    right: 15px;
}


.password-strength{
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100px;
    height:5px;
    align-self: flex-end;
}
.password-strength .meter{
    width: 20px;
    height: 100%;
}